<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Conteúdos - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'contents'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-content :content="content" :update="true"></form-content>
</span>
</template>

<script>
import FormContentComponent from "./partial/FormContentComponent";

export default {
    name: "EditContentComponent",
    created() {
        this.loadContent()
    },
    props: {
        cont_id: {
            require: true
        }
    },
    data() {
        return {
            content: {},
            langs: true
        }
    },
    methods: {
        loadContent() {
            this.$store.dispatch('loadContent', this.cont_id)
                .then(response => this.content = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formContent: FormContentComponent
    }
};
</script>

<style scoped>

</style>
