<template>
<span>
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>

               <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <v-radio-group v-model="content.language_id" :column="false">
                        <v-radio class="mr-5" color="lime" v-for="(lang, index) in languages" :key="index" :label="lang.lang_descricao" :value="lang.lang_id"></v-radio>
                    </v-radio-group>

                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-select v-model="content.content_category_id" :items="loadContentCategories" item-text="cont_c_nome" item-value="cont_c_id" label="Selecione uma categoria..." :rules="rules.contentCategoryRules" required outlined></v-select>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="content.cont_titulo" :rules="rules.tituloRules" label="Título" outlined required></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea v-model="content.cont_resumo" :rules="rules.resumoRules" :counter="600" name="resumo" label="Resumo" outlined></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field v-model="content.cont_data" label="Data" v-mask="'##/##/####'" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field v-model="content.cont_local" label="Local" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field v-model="content.cont_fonte" label="Fonte" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="content.cont_fonteurl" label="Fonte (URL)" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="content.cont_autor" label="Autor" outlined></v-text-field>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-textarea v-model="content.cont_meta_descricao" :rules="rules.metaDescricaoRules" :counter="180" name="meta_descricao" label="Meta Descrição" outlined required></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <v-textarea v-model="content.cont_keywords" :rules="rules.keywordsRules" :counter="180" name="keywords" label="Palavras Chaves" outlined required></v-textarea>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">

                    <editor-menu-bubble class="menububble" :editor="editor" @hide="hideLinkMenu" v-slot="{ commands, isActive, getMarkAttrs, menu }">
                        <div class="menububble" :class="{ 'is-active': menu.isActive }" :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`">

                            <v-flex xs6 p-5>
                                <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                                    <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu" />
                                    <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                                        <v-icon>cancel</v-icon>
                                    </button>
                                </form>
                            </v-flex>

                            <v-btn-toggle v-model="toggle_multiple" multiple>
                                <v-btn text :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">
                                    H2
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">
                                    H3
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.heading({ level: 4 }) }" @click="commands.heading({ level: 4 })">
                                    H4
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
                                    <v-icon>format_bold</v-icon>
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
                                    <v-icon>format_italic</v-icon>
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
                                    <v-icon>format_underlined</v-icon>
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.code() }" @click="commands.code">
                                    <v-icon>code</v-icon>
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
                                    <v-icon>format_textdirection_l_to_r</v-icon>
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
                                    <v-icon>format_list_bulleted</v-icon>
                                </v-btn>
                                <v-btn text :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
                                    <v-icon>format_list_numbered</v-icon>
                                </v-btn>
                                <v-btn text @click="commands.horizontal_rule">
                                    <v-icon>space_bar</v-icon>
                                </v-btn>
                                <v-btn text @click="commands.undo">
                                    <v-icon>undo</v-icon>
                                </v-btn>
                                <v-btn text @click="commands.redo">
                                    <v-icon>redo</v-icon>
                                </v-btn>

                                <v-btn class="menububble__button" @click="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
                                    <span>{{ isActive.link() ? 'Update Link' : 'Add Link'}}</span>
                                    <v-icon name="link">link</v-icon>
                                </v-btn>

                            </v-btn-toggle>

                        </div>
                    </editor-menu-bubble>

                    <editor-content class="editor__content" :editor="editor" :rules="rules.textoRules" required outline />

                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_publicado" label="Publicado"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_especial" label="Especial"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_destaque_home" label="Destaque Home"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_destaque24h" label="Destaque 24h"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_destaque_video" label="Destaque Vídeo"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_status" label="Status"></v-switch>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="2">
                    <v-switch color="lime" v-model="content.cont_islink" label="É Link?"></v-switch>
                </v-col>

                <v-col cols="12" class="text-right">
                    <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                        {{btnLabel}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'
import Icon from '../../../../components/Icon'
import {
    Editor,
    EditorContent,
    EditorMenuBubble
} from 'tiptap'
import {
    Blockquote,
    CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Underline,
    History,
} from 'tiptap-extensions'
import cep from "cep-promise";

export default {
    props: {
        content: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    cont_id: '',
                    language_id: 1,
                    content_category_id: '',
                    cont_titulo: '',
                    cont_slug: '',
                    cont_resumo: '',
                    cont_texto: '',
                    cont_fonte: '',
                    cont_fonteurl: '',
                    cont_autor: '',
                    cont_clicks: 1,
                    cont_data: '',
                    cont_local: '',
                    cont_publicado: true,
                    cont_especial: false,
                    cont_destaque_home: false,
                    cont_destaque24h: false,
                    cont_destaque_video: false,
                    cont_meta_descricao: '',
                    cont_keywords: '',
                    cont_video: '',
                    cont_status: true,
                    cont_islink: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {
        this.$store.dispatch('loadLanguages');
        this.$store.dispatch('loadContentCategories');
    },
    computed: {
        loadContentCategories() {
            return this.$store.state.contentcategories.items.data;
        },
        languages() {
            return this.$store.state.languages.items.data;
        }
    },
    watch: {
        content() {
            this.setEditorContent();
        }
    },
    name: "FormContentComponent",

    data() {
        return {
            valid: true,
            toggle_multiple: [],
            editor: new Editor({
                extensions: [
                    new Blockquote(),
                    new BulletList(),
                    new CodeBlock(),
                    new HardBreak(),
                    new Heading({
                        levels: [1, 2, 3, 4]
                    }),
                    new HorizontalRule(),
                    new ListItem(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Link(),
                    new Bold(),
                    new Code(),
                    new Italic(),
                    new Underline(),
                    new History(),
                ],
                content: '',
            }),
            linkUrl: null,
            linkMenuIsActive: false,
            rules: {
                tituloRules: [
                    v => !!v || 'Campo Título é obrigatório',
                    v => (v && v.length <= 200) || 'Título precisa ter até 200 Caracteres'
                ],
                resumoRules: [
                    v => !!v || 'Campo Resumo é obrigatório',
                    v => (v && v.length <= 600) || 'Resumo precisa ter até 200 Caracteres'
                ],
                contentCategoryRules: [
                    v => !!v || 'Campo Categoria é obrigatório',
                ],
                textoRules: [
                    v => !!v || 'Campo Texto é obrigatório',
                ],
                metaDescricaoRules: [
                    v => !!v || 'Campo Meta descrição é obrigatório',
                    v => (v && v.length <= 180) || 'Meta Descrição deve ter até 180 caracteres'
                ],
                keywordsRules: [
                    v => !!v || 'Campo Palavras Chaves é obrigatório',
                    v => (v && v.length <= 180) || 'Palavras Chaves deve ter até 180 caracteres'
                ],
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    beforeDestroy() {
        // Always destroy your editor instance when it's no longer needed
        this.editor.destroy()
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(command, url) {
            command({
                href: url
            })
            this.hideLinkMenu()
        },
        setEditorContent() {
            this.editor.setContent(this.content.cont_texto);
        },
        log(msg) {
            console.log(msg)
        },
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateContent' : 'storeContent';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                this.content.cont_texto = this.editor.getHTML();

                this.$store.dispatch(action, this.content)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'contents'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        showImagePrompt(command) {
            const src = prompt('Entre com a URL da magem')
            if (src !== null) {
                command({
                    src
                })
            }
        },
    },
    components: {
        EditorContent,
        EditorMenuBubble,
        Icon,
    },
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-salvar {
    margin: 0;
}

.editor__content {
    border: 1px solid #6f6e6e;
    padding: 0.5rem;
}

.ProseMirror {
    border: none;
    outline: 0;
}

.ProseMirror,
.ProseMirror-focused,
.ProseMirror-focused:focus,
textarea:focus,
input:focus,
select:focus {
    border: none !important;
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.menububble__form {
    background-color: #212121;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 0 0 10px 0;
}

.menububble__input {
    background-color: #212121;
    color: #fff;
    font: inherit;
    border: none;
    width: 70%;
}
</style>
